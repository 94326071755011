import {createSlice, PayloadAction} from '@reduxjs/toolkit';

/**
 * Represents user information.
 */
export type User = {
	username?: string | null;
	email: string;
	name: string;
	account?: string;
	groups: Array<string>;
};

/**
 * The authentication state.
 */
export type AuthState = {
	user: User | null | undefined;
};

export const authInitialState: AuthState = {
	user: undefined
};

export const authSlice = createSlice({
	name: 'auth',
	initialState: authInitialState,
	reducers: {
		/**
		 * Sets a user in the Redux store state.
		 * @param state - Current authentication state in the Redux store.
		 * @param action - Redux action containing user data or null.
		 * @returns New state with updated user information.
		 */
		setUser: (state: AuthState, action: PayloadAction<User | null>): AuthState => ({
			...state,
			user: action.payload === null ? null : {...action.payload}
		})
	}
});
