import React, {useState, useEffect, useRef} from 'react';
import styles from './DevicesPage.module.scss';
import {
	ColumnConfig,
	Table,
	useSnackbar,
	Chip,
	pixidaTheme,
	ColdNeutral,
	SearchTextField,
	Button
} from '@easerill/pixida-group-ui';
import {listDevice} from '../../api/devices';
import {Device} from '@fleetkey/types';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import {MAX_TIMESTAMP} from '../../constants';
import {EditIcon} from '../../components/Icons';
import MuiIconButton from '@mui/material/IconButton';
import {EditDevice} from './EditDevice';
import {BiX} from 'react-icons/bi';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

/**
 * Represents a devices component.
 * @returns The rendered devices component.
 */
export const DevicesPage = () => {
	const navigate = useNavigate();

	const [devices, setDevices] = useState<Device[]>([]);
	const [allDevices, setAllDevices] = useState<Device[]>([]);
	const {triggerSnackbar} = useSnackbar();
	const [isLoading, setIsLoading] = useState(true);
	const [editDeviceOpen, setEditDeviceOpen] = useState(false);
	const [editingDevice, setEditingDevice] = useState<Device>();

	const [searchQuery, setSearchQuery] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const searchRef = useRef<any>(null);
	const {SN} = useParams();

	const tableHeaderConfig: ColumnConfig<Device>[] = [
		{
			id: 'assignedVehicle',
			label: 'Assigned Vehicle',
			width: '40%',
			/**
			 * Function to render the column
			 * @param rowData The row data
			 * @returns The rendered column element.
			 */
			render: (rowData) => {
				return (
					<div>
						<MuiIconButton
							size="small"
							onClick={() => handleEditDeviceClick(rowData.data)}
						>
							<EditIcon sx={{color: ColdNeutral[300]}} />
						</MuiIconButton>
						<span
							className={styles.serialNumberCell}
							style={{color: pixidaTheme.palette.primary.main, marginLeft: '8px'}}
						>
							{rowData.data.assignedVehicle}
						</span>
					</div>
				);
			}
		},
		{
			id: 'serialNumber',
			label: 'Serial Number',
			width: '20%',
			/**
			 * Function to render the column
			 * @param rowData The row data
			 * @returns The rendered column element.
			 */
			render: (rowData) => {
				return <span>{rowData.data.serialNumber}</span>;
			}
		},
		{
			id: 'busManufacturer',
			label: 'Signal Profile',
			width: '20%',
			/**
			 * Function to render the column
			 * @param rowData The row data
			 * @returns The rendered column element.
			 */
			render: (rowData) => {
				return <span>{rowData.data.busManufacturer}</span>;
			}
		},
		{
			id: 'lastSeen',
			label: 'Last seen',
			width: '20%',
			/**
			 * Function to render the column
			 * @param rowData The row data
			 * @returns The rendered column element.
			 */
			render: (rowData) => {
				const {connected, lastSeen} = rowData.data;

				let label = <Chip text="never" variation="danger" />;
				if (connected) {
					label = <Chip text="online" variation="success" />;
				} else if (lastSeen) {
					label = <span>{timeAgo.format(lastSeen, 'round')}</span>;
				}

				return label;
			}
		}
	];

	/**
	 * Function to filter the devices based on the search query
	 * @param searchQuery search query
	 * @param allDevices list of all devices
	 */
	function applySearchFilter(searchQuery: string, allDevices: Device[]) {
		if (searchQuery) {
			const lowerCaseQuery = searchQuery.toLowerCase();
			const filteredDevices = allDevices.filter(
				(device) =>
					device.serialNumber.toLowerCase().includes(lowerCaseQuery) ||
					device.assignedVehicle?.toLowerCase().includes(lowerCaseQuery)
			);
			setDevices(filteredDevices);
		} else {
			setDevices(allDevices);
		}
	}

	/**
	 * Resets the page without search and filter
	 */
	const resetSearch = () => {
		setSearchQuery('');
		navigate('/devices');
	};

	useEffect(() => {
		listDevice()
			.then((devices) => {
				// If the device is online, set lastSeen to MAX_TIMESTAMP for order purposes.
				devices.forEach((device) => {
					if (device.connected) {
						device.lastSeen = MAX_TIMESTAMP;
					}
				});
				setAllDevices(devices);
			})
			.finally(() => setIsLoading(false))
			.catch(() => {
				triggerSnackbar('Error fetching devices', 'error');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (SN) {
			setSearchQuery(SN);
		} else {
			setSearchQuery('');
		}
	}, [SN]);

	useEffect(() => {
		applySearchFilter(searchQuery, allDevices);
	}, [allDevices, searchQuery]);

	/**
	 * a sa
	 * @param device asd
	 */
	const handleEditDeviceClick = async (device: Device) => {
		setEditDeviceOpen(true);
		setEditingDevice(device);
	};

	/**
	 * Handles the event when the modal for editing a device is closed.
	 */
	const handleCancelEditDevice = () => {
		setEditDeviceOpen(false);
	};

	/**
	 * Handles the event when the device infomration are changed.
	 * @param newDevice The updated device
	 */
	const handleSaveEditDevice = (newDevice: Device) => {
		const updatedDevice = devices.find(
			(device) => device.serialNumber === newDevice.serialNumber
		);
		if (updatedDevice) {
			updatedDevice.assignedVehicle = newDevice.assignedVehicle;
			updatedDevice.busManufacturer = newDevice.busManufacturer;
		}
		setEditDeviceOpen(false);
	};

	return (
		<>
			<div className={styles.searchFieldContainer}>
				<div className={styles.searchField}>
					<SearchTextField
						value={searchQuery}
						onSubmitSearch={setSearchQuery}
						ref={searchRef}
						disabled={isLoading}
						size={'small'}
						onChange={setSearchQuery}
					/>
				</div>
				<Button
					text={'Reset'}
					variation={'ghost'}
					className={styles.resetButton}
					startIcon={<BiX />}
					onClick={resetSearch}
					disabled={isLoading}
				/>
			</div>

			{devices.length > 0 || isLoading ? (
				<Table
					title={''}
					columnsConfig={tableHeaderConfig}
					data={devices}
					rowsPerPageOptions={[10, 25, 50, 100]}
					showBorder={false}
					hideToolbar={true}
					isLoading={isLoading}
					defaultOrderBy={'serialNumber'}
					defaultOrder={'asc'}
				/>
			) : (
				<div className={styles.noDevicesMessage}>No devices found.</div>
			)}
			{editDeviceOpen ? (
				<EditDevice
					open={editDeviceOpen}
					device={editingDevice!}
					onCancel={handleCancelEditDevice}
					onSave={handleSaveEditDevice}
				/>
			) : null}
		</>
	);
};
