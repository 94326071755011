import axios from './axios';
import {TOKEN_URL, REFRESH_URL, LOGOUT_URL} from '../constants';
import {jwtDecode} from 'jwt-decode';

/**
 * Represents a token containing user information.
 */
interface JwtToken {
	name: string;
	'cognito:username': string;
	account: string;
	email: string;
	'cognito:groups': string[];
}

/**
 * Represents user information.
 */
export interface UserInfo {
	name: string;
	username: string;
	account: string;
	email: string;
	groups: string[];
}

/**
 * Represents authentication information.
 */
export interface AuthInfo {
	userInfo: UserInfo | null;
	idToken?: string;
	accessToken?: string;
}

/**
 * Retrieves user information from an authenticated token.
 * @param token - The authentication token. If null, the function returns null.
 * @returns {object | null} User information, or null if the token is invalid or not provided.
 */
const getAuthenticatedUserInfo = (token: string | null): UserInfo | null => {
	const decodedToken = token ? jwtDecode<JwtToken>(token) : null;
	if (decodedToken) {
		return {
			name: decodedToken.name,
			username: decodedToken['cognito:username'],
			account: decodedToken.account,
			email: decodedToken.email,
			groups: decodedToken['cognito:groups']
		};
	}
	return decodedToken;
};

/**
 * Removes authentication tokens from local storage.
 */
export const removeTokens = () => {
	window.localStorage.removeItem('idToken');
	window.localStorage.removeItem('accessToken');
};

/**
 * Authenticates the user by exchanging the authorization code for access tokens.
 * @returns {Promise<object | null>} An object containing user information if authentication is successful, otherwise null.
 */
export const authenticate = async () => {
	const uriParams = new URLSearchParams(window.location.search);
	const code = uriParams.get('code');
	if (code) {
		try {
			const response = await axios.post(TOKEN_URL, {code}, {withCredentials: true});
			window.localStorage.setItem('idToken', response.data.idToken);
			window.localStorage.setItem('accessToken', response.data.accessToken);
			return getAuthenticatedUserInfo(response.data.idToken);
		} catch (err) {
			console.error(err);
			return null;
		}
	} else {
		const {userInfo} = await refreshAuth();
		return userInfo;
	}
};

/**
 * Refreshes the authentication tokens using the refresh token.
 * @returns {Promise<object>} An object containing user information and tokens.
 */
export const refreshAuth = async (): Promise<AuthInfo> => {
	try {
		const response = await axios.post(REFRESH_URL, {}, {withCredentials: true});
		window.localStorage.setItem('idToken', response.data.idToken);
		window.localStorage.setItem('accessToken', response.data.accessToken);
		return {
			userInfo: getAuthenticatedUserInfo(response.data.idToken),
			idToken: response.data.idToken,
			accessToken: response.data.accessToken
		};
	} catch {
		removeTokens();
		return {userInfo: null};
	}
};

/**
 * Signs out the user by revoking access and ID tokens.
 * @returns {Promise<void>} A promise that resolves when the sign-out process is complete.
 */
export const signOut = async () => {
	const accessToken = window.localStorage.getItem('accessToken');
	const idToken = window.localStorage.getItem('idToken');
	return axios
		.post(LOGOUT_URL, {accessToken, idToken}, {withCredentials: true})
		.then(() => {
			removeTokens();
		})
		.catch((error) => {
			throw error;
		});
};
