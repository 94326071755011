import React, {useEffect} from 'react';
import {RouteProps, useNavigate} from 'react-router-dom';
import {AUTH_BASE_URL} from '../../constants';
import Layout from '../Layout/Layout';
import {Routes, RouteItem} from '@easerill/pixida-group-ui';

/**
 * Properties of a protected route
 */
declare type IProtectedRoute = RouteProps & {
	currentRoute: RouteItem;
	userRoles: string[];
	routes: Routes;
	userAccount?: string;
};

/**
 * Properties for the Redirect component
 */
type RedirectProps = {
	to: string;
};

/**
 * Redirects `to` a given URL
 * @param props Redirect props objects
 * @param props.to The URL to be redirected to
 * @returns the redirect element.
 */
const Redirect = ({to}: RedirectProps) => {
	useEffect(() => {
		window.location.href = to;
	});
	return null;
};

/**
 * Properties for the Navigate component
 */
type NavigateProps = {
	to: string;
};
/**
 * Redirects `to` a given URL
 * @param props Navigate props objects
 * @param props.to The URL to be navigated to
 * @returns the navigate element.
 */
const Navigate = ({to}: NavigateProps) => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(to);
	});
	return null;
};

/**
 * Represents a route that cannot be accessed without login.
 * @param props Properties of a protected route.
 * @returns The protected route element.
 */
const ProtectedRoute = (props: IProtectedRoute) => {
	const {currentRoute, userRoles, element, userAccount, routes} = props;
	const isSignedIn = Boolean(userRoles.length);

	let protectedElement = null;
	if (
		currentRoute.account &&
		currentRoute.account === userAccount &&
		isSignedIn &&
		currentRoute.roles.some((r) => userRoles.includes(r)) &&
		element
	) {
		protectedElement = element;
	} else if (isSignedIn && currentRoute.roles.some((r) => userRoles.includes(r)) && element) {
		protectedElement = element;
	} else if (isSignedIn) {
		protectedElement = <Navigate to="/devices" />;
	} else {
		protectedElement = (
			<Redirect to={`${AUTH_BASE_URL}/login/?redirect_uri=${window.origin}`} />
		);
	}

	return (
		<Layout routes={routes} userRoles={userRoles}>
			{protectedElement}
		</Layout>
	);
};

export default ProtectedRoute;
