import React, {useEffect} from 'react';
import {Button, Modal, ModalProps, TextField, useSnackbar} from '@easerill/pixida-group-ui';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Backdrop, CircularProgress, Autocomplete, TextField as MuiTextField} from '@mui/material';
import {Device} from '@fleetkey/types';
import {update} from '../../api/devices';

import {listSignalProfiles} from '../../api/signalProfiles';

/**
 * Props for the EditDevice component.
 */
export type EditDeviceProps = {
	device: Device;
	onCancel: () => void;
	onSave: (editedDevice: Device) => void;
} & Omit<ModalProps, 'title' | 'onClose'>;

/**
 * Component to edit device information.
 * @param {EditDeviceProps} props - The props for the EditDevice component.
 * @returns The EditDevice component.
 */
export const EditDevice = ({onCancel, onSave, open, device}: EditDeviceProps) => {
	const [editedDevice, setEditedDevice] = React.useState<Device>({...device});
	const [availableSignalProfiles, setAvailableSignalProfiles] = React.useState<string[]>([]);
	const [loading, setLoading] = React.useState(false);
	const {triggerSnackbar} = useSnackbar();

	useEffect(() => {
		listSignalProfiles()
			.then((signalProfiles) => {
				setAvailableSignalProfiles(
					signalProfiles.map((signalProfile) => signalProfile.showName)
				);
			})
			.catch(() => {
				triggerSnackbar('Error fetching signal profiles', 'error');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Handles the click event when the user cancels the device edit process.
	 */
	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	/**
	 * Handles the save click event
	 */
	const handleSaveClick = () => {
		setLoading(true);
		editedDevice.busManufacturer = editedDevice.busManufacturer || 'Generic';
		update(editedDevice.serialNumber, {
			assignedVehicle: editedDevice.assignedVehicle,
			busManufacturer: editedDevice.busManufacturer
		})
			.then(() => {
				triggerSnackbar('Successfully updated the device configuration', 'success');
				if (onSave) {
					onSave(editedDevice);
				}
			})
			.catch((err) => {
				if (err.response?.status === 400 && err.response?.data?.message) {
					triggerSnackbar(err.response.data.message, 'error');
				} else {
					triggerSnackbar('There was an error updating the device', 'error');
				}
			})
			.finally(() => setLoading(false));
	};

	return (
		<Modal open={open} onClose={handleCancelClick} title="Device Configuration">
			<Backdrop
				sx={{
					color: '#fff',
					/**
					 * Sets the z-order of a positioned element
					 * @param {object} theme - The MUI theme object.
					 * @returns {number} The calculated z-index value for the backdrop.
					 */
					zIndex: (theme) => theme.zIndex.drawer + 1
				}}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<TextField
				id="assignedVehicle"
				value={editedDevice.assignedVehicle ?? ''}
				label="Assigned Vehicle"
				placeholder="Type down a name. Eg.: the license plate of the bus"
				onChange={(text: string) =>
					setEditedDevice((prev) => ({...prev, assignedVehicle: text}))
				}
				maxLength={30}
			/>
			<Autocomplete
				id="busManufacturer"
				value={editedDevice.busManufacturer}
				autoHighlight
				onChange={(
					event: React.SyntheticEvent<Element, Event> | null,
					newValue: string | null
				) => {
					setEditedDevice((prev) => ({
						...prev,
						busManufacturer: newValue || ''
					}));
				}}
				options={availableSignalProfiles}
				renderInput={(params) => <MuiTextField {...params} label="Signal Profile" />}
			/>
			<div style={{marginLeft: 'auto', display: 'flex', gap: '16px'}}>
				<Button
					variation="info"
					text="Cancel"
					onClick={handleCancelClick}
					startIcon={<HighlightOffIcon />}
				/>
				<Button
					variation="success"
					text="Save"
					onClick={handleSaveClick}
					startIcon={<CheckCircleOutlineIcon />}
				/>
			</div>
		</Modal>
	);
};
