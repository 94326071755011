import axios from './axios';
import {BASE_URL} from '../constants';
import {Device, UpdateDeviceRequest} from '@fleetkey/types';

/**
 * Calls the API to retrieve the device list.
 * @returns a list of devices
 */
export const listDevice = async (): Promise<Device[]> => {
	const response = await axios.get(`${BASE_URL}/v1/devices`);
	return response.data.devices;
};

/**
 * Partially updates a device
 * @param serialNumber - A serial number of device to be updated.
 * @param body - The new information to be updated into the device
 */
export const update = async (serialNumber: string, body: UpdateDeviceRequest) => {
	await axios.patch(`${BASE_URL}/v1/devices/${serialNumber}`, body);
};
