import React, {
	ForwardedRef,
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
	useEffect,
} from 'react';
import { IconButton as MuiIconButton, Paper as MuiPaper } from '@mui/material';
import { BiSearchAlt } from 'react-icons/bi';
import { TextField, TextFieldProps } from '../TextField';
import styles from './SearchTextField.module.scss';

/**
 *
 */
type InputSearchProps = Omit<
	TextFieldProps,
	'id' | 'value' | 'label' | 'placeholder'
> & {
	value?: string;
	// eslint-disable-next-line no-unused-vars
	onSubmitSearch?: (searchText: string) => void;
	ref: ForwardedRef<any>;
};

const SearchTextFieldRef = (
	{
		onChange,
		value = '',
		onSubmitSearch,
		helperText,
		size = 'medium',
		disabled,
		...restProps
	}: InputSearchProps,
	ref: ForwardedRef<any>,
) => {
	const formRef = useRef(null);
	const [searchText, setSearchText] = useState<string>(value);

	useEffect(() => {
		if (value !== undefined) {
			setSearchText(value);
		}
	}, [value]);

	useImperativeHandle(ref, () => ({
		reset() {
			resetForm();
		},
	}));

	const handleOnSearchTextChange = (text: string) => {
		setSearchText(text);
		if (onChange) {
			onChange(text);
		}
	};

	const handleOnFormSubmit = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (onSubmitSearch) {
			onSubmitSearch(searchText);
		}
	};

	const resetForm = () => {
		if (formRef.current) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			formRef.current.reset();
		}
	};

	return (
		<MuiPaper
			elevation={0}
			component="form"
			onSubmit={handleOnFormSubmit}
			ref={formRef}
			sx={{
				backgroundColor: 'transparent',
			}}
		>
			<TextField
				id="search"
				label="Search"
				placeholder="Search"
				errorText={helperText ? helperText : undefined}
				helperText={helperText ? helperText : undefined}
				value={searchText}
				{...restProps}
				className={styles.searchText}
				onChange={handleOnSearchTextChange}
				size={size}
				disabled={disabled}
				endIcon={
					<MuiIconButton type="submit" aria-label="search" disabled={disabled}>
						<BiSearchAlt />
					</MuiIconButton>
				}
			/>
		</MuiPaper>
	);
};

const SearchTextField = forwardRef(SearchTextFieldRef);
export { SearchTextField };
