import {combineReducers} from 'redux';
import {authInitialState, authSlice} from './Auth';

/**
 * Represents the root state.
 */
export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
	auth: authSlice.reducer
});

export const rootInitialState: RootState = {
	auth: authInitialState
};
