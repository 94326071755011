import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux';
import {authSlice, User} from '../../redux/Auth';

/**
 * The object returned by `useStateAuth`
 */
type ReturnType = {
	user: User | null | undefined;
	setUser: (user: User | null) => void;
};

/**
 * Custom hook for handling Auth data in the Redux state.
 * @returns {ReturnType} See `ReturnType`.
 */
const useStateAuth = (): ReturnType => {
	const dispatch = useDispatch();

	const user = useSelector<RootState, User | null | undefined>(
		(state: RootState) => state.auth.user
	);

	/**
	 * Set the user in the Redux store.
	 * @param user - A User object or null if there is no user.
	 */
	const setUser = (user: User | null) => {
		dispatch(authSlice.actions.setUser(user));
	};

	return {
		user,
		setUser
	};
};

export default useStateAuth;
