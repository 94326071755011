const origin = window.location.origin;

export const AUTH_BASE_URL =
	origin.includes('localhost') || origin.includes('127.0.0.1') || origin.includes('dev')
		? 'https://dev.auth.pi-labs.eu'
		: 'https://auth.pi-labs.eu';

export const BASE_URL =
	origin.includes('localhost') || origin.includes('127.0.0.1') || origin.includes('dev')
		? 'https://api.dev.fleetkey.pi-labs.eu'
		: 'https://api.fleetkey.pi-labs.eu';

export const FILES_BASE_URL =
	origin.includes('localhost') || origin.includes('127.0.0.1') || origin.includes('dev')
		? 'https://dev.files.pi-labs.eu'
		: 'https://files.pi-labs.eu';

export const TOKEN_URL = `${AUTH_BASE_URL}/oauth2/token`;
export const REFRESH_URL = `${AUTH_BASE_URL}/oauth2/refresh`;
export const LOGOUT_URL = `${AUTH_BASE_URL}/oauth2/logout`;
export const MAX_TIMESTAMP = 8640000000000000;
