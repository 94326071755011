import axios from './axios';
import {BASE_URL} from '../constants';
import {SignalProfile} from '@fleetkey/types';

/**
 * Calls the API to retrieve the signal profile list.
 * @returns a list of available signal profiles
 */
export const listSignalProfiles = async (): Promise<SignalProfile[]> => {
	const response = await axios.get(`${BASE_URL}/v1/signal-profiles`);
	return response.data.signalProfiles;
};
