import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import {DevicesPage} from '../pages/Devices';
import {User} from '../redux/Auth';
import useStateAuth from '../hooks/state/useStateAuth';
import useCustomerHive from '../hooks/api/useCustomerHive';
import {useDispatch} from 'react-redux';
import {Routes as PixidaRoutes} from '@easerill/pixida-group-ui';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './AppRoute.module.scss';

const ROOT = 'Root';
const ADMIN = 'Admin';
const FLEET_MANAGER = 'FleetManager';
const FLEET_USER = 'FleetUser';

export const routes: PixidaRoutes = {
	devices: {
		uri: '/devices',
		label: 'Devices',
		roles: [ROOT, ADMIN, FLEET_MANAGER, FLEET_USER]
	}
};

/**
 * The routes of the application.
 * @returns An element representing the routes of the application.
 */
const AppRoutes = () => {
	const {user, setUser} = useStateAuth();
	const {authenticate, clearLocationUrl} = useCustomerHive();
	const dispatch = useDispatch();

	useEffect(() => {
		if (user === undefined || user === null) {
			authenticate().then((authenticatedUser: User | null) => {
				setUser(authenticatedUser);
				clearLocationUrl();
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, dispatch]);

	if (user === undefined) {
		return (
			<div className={styles.loadingContainer}>
				<CircularProgress size={100} />
			</div>
		);
	}
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Navigate to="/devices" replace />} />
				<Route
					path="/devices"
					element={
						<ProtectedRoute
							currentRoute={routes.devices}
							userAccount={user?.account}
							routes={routes}
							userRoles={user?.groups ?? []}
							element={<DevicesPage />}
						/>
					}
				/>
				<Route
					path="/devices/:SN"
					element={
						<ProtectedRoute
							currentRoute={routes.devices}
							userAccount={user?.account}
							routes={routes}
							userRoles={user?.groups ?? []}
							element={<DevicesPage />}
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;
