import {User} from '../../redux/Auth';
import {authenticate, signOut} from '../../api/auth';

/**
 * The object returned by `useCustomerHive`
 */
type ReturnType = {
	/** Authenticate the user */
	authenticate: () => Promise<User | null>;
	signOut: () => Promise<void>;
	clearLocationUrl: () => void;
};

/**
 * Custom hook for handling API requests to the Customer Hive.
 * @returns {ReturnType} See `ReturnType`.
 */
const useCustomerHive = (): ReturnType => {
	/**
	 * Clears the location URL.
	 */
	const clearLocationUrl = (): void => {
		window.history.pushState({}, 'home', window.location.pathname);
	};

	/**
	 * Handles authentication.
	 * @returns {Promise<User | null>} A Promise resolving to a User or null.
	 */
	const handleAuthenticate = async (): Promise<User | null> => {
		return await authenticate();
	};

	/**
	 * Handles logout.
	 */
	const handleSignOut = async () => {
		await signOut();
	};

	return {
		authenticate: handleAuthenticate,
		clearLocationUrl,
		signOut: handleSignOut
	};
};

export default useCustomerHive;
